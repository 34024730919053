import React from 'react';
import { navigate, Link } from 'gatsby';

import StorageService from '../../services/storage.service';
import http from '../../services/http.service';
import Layout from '../../components/Layout/Layout';
import LandingInfoWrapper from '../../components/Landing-info/LandingInfoWrapper/LandingInfoWrapper';
import { useDisplayServerError } from '../../hooks/useDisplayServerError';
import { LOCALSTORAGE_KEYS } from '../../constans/localstorage';
import { API } from '../../constans/http';
import SignUpForm from './form';

import classes from './index.module.scss';

function SignUp() {
  const [serverError, setServerError] = useDisplayServerError('');

  const formSubmitHandler = (values) => {
    http
      .post(API.USER_REG, { ...values })
      .then(({ data }) => {
        StorageService().set(LOCALSTORAGE_KEYS.REGUSER, data);
        navigate('/email-notif');
      })
      .catch((error) => {
        setServerError(error.message);
      });
  };

  return (
    <div className="footer-hidden column-flexed-wrapper">
      <Layout>
        <div
          className={`${classes.section} blue-grad-bg flex-grow-1 flex flex-v-center`}
        >
          <div className="auth-wrapper container-bordered">
            <div className="auth-title block-title">
              Sign Up
              { /* serverError && <div className="titleError">{serverError}</div> */}
            </div>
            <SignUpForm formSubmitHandler={formSubmitHandler} />
            <div className={classes.agreeBlock}>
              By pressing the Register button you are agreeing to the
            </div>
            <LandingInfoWrapper
              btnsWrapper={classes.infoWrapper}
              btn={classes.infoBtn}
              delimiter={<span className={classes.infoDelimiter}>&amp;</span>}
            />
            <div className={`${classes.signInWrapper} t-600`}>
              <span>Already have an account?</span>
              {' '}
              <Link to="/signin/">Sign In</Link>
            </div>
          </div>
        </div>
      </Layout>
    </div>
  );
}

export default SignUp;
